<template>
    <v-container class="mainRow pa-0 ma-0" fluid>
        <!-- https://dashboard.emailjs.com/admin -->
        <v-row class="pa-0 ma-0 ribon">
            <v-col>
                <h1>Nous contacter</h1>
            </v-col>
        </v-row>

        <v-row fuild class="pa-0 py-6 ma-0" justify="center">
            <v-col cols="12" align="center">
                <h1 class="divider">
                    Si vous souhaitez nous contacter ...
                </h1>
            </v-col>
        </v-row>
        <v-row justify="center" class="pa-0 mx-0 mt-6 mb-3">
            <!-- DROITE / Formulaire de contact -->
            <v-col lg="4" md="8" sm="10" xs="12" class="py-0 px-3 mx-0 mb-6">
                <v-card class="pa-3">
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col>
                                <v-text-field v-model="fname" label="Prénom" :rules="nameRules" required>
                                </v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="lname" label="Nom" :rules="nameRules" required>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!-- Adresse email -->
                        <v-text-field v-model="email" class="mv-tfield" label="Adresse email" :rules="emailRules" required>
                        </v-text-field>
                        <v-select v-model="motif" :items="motifList" :rules="[v => !!v || 'Un motif est requis']"
                            item-text="libelle" class="mv-tfield" label="Motif de la demande">
                        </v-select>
                        <!-- Message -->
                        <v-textarea v-model="msgCtnt" label="Votre message ici" maxlength="300" class="mv-tfield"
                            :rules="[v => !!v || 'Le contenu du message ne peut pas être vide']" counter
                            required></v-textarea>
                        <v-btn large block class="info lighten-1" @click="validate()">
                            Envoyer
                        </v-btn>
                    </v-form>
                </v-card>
            </v-col>
            <!-- GAUCHE / Formulaire de contact -->
            <v-col lg="4" md="8" sm="10" xs="12" class="py-0 px-3 mx-0 mb-6">
                <v-card class="pa-4 rad">
                    <v-card-title>
                        Nous sommes à votre écoute pour :
                    </v-card-title>
                    <v-card-text class="vtxt">
                        <v-list>
                            <v-list-item class="pa-0 ma-0">
                                <v-list-item-icon>
                                    <v-icon>mdi-menu-right</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <span class="vtxt">Un devis</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="pa-0 ma-0">
                                <v-list-item-icon>
                                    <v-icon>mdi-menu-right</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <span class="vtxt">Un renseignement</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="pa-0 ma-0">
                                <v-list-item-icon>
                                    <v-icon>mdi-menu-right</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <span class="vtxt">Un conseil</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="pa-0 ma-0">
                                <v-list-item-icon>
                                    <v-icon>mdi-menu-right</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <span class="vtxt">Une intervention</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item class="pa-0 ma-0">
                                <v-list-item-icon>
                                    <v-icon>mdi-menu-right</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <span class="vtxt">Une expertise</span>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                    <v-card-text class="vtxt">
                        Nous vous prions de bien vouloir nous adresser votre demande
                        par le biais du formulaire de contact, ci-contre.
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import emailjs from 'emailjs-com';

// https://itnext.io/how-to-use-google-recaptcha-with-vuejs-7756244400da
// Exemple de formulaire de contact

// emailjs
// https://www.npmjs.com/package/emailjs-com

export default {
    data() {
        return {
            testContent: "",
            valid: true,
            ldng: false,
            fname: "",
            lname: "",
            email: "",
            msgCtnt: "",
            motif: null,
            nameRules: [
                v => !!v || 'Ce champ est requis',
                v => (v && v.length <= 10) || 'Name must be less than 10 characters',
            ],
            emailRules: [
                v => !!v || 'Une adresse email est requise',
                v => /.+@.+\..+/.test(v) || "L'adresse email doit être valide",
            ],
            motifList: [
                {
                    id: 0,
                    libelle: "Devis",
                },
                {
                    id: 1,
                    libelle: "Pose de borne électrique",
                },
                {
                    id: 2,
                    libelle: "Intervention",
                },
                {
                    id: 3,
                    libelle: "Expertise",
                },
                {
                    id: 4,
                    libelle: "Un renseignement",
                }
            ]
        }
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.sendEmail()
            } else {
                this.snckBar = {
                    visible: true,
                    category: "error",
                    msg: "Tous les champs ne sont pas remplis correctement",
                    timeout: 5000
                }
            }
        },
        sendEmail() {
            var templateParams = {
                to_name: "CEFED",
                from_name: `${this.fname} ${this.lname}`,
                motif: this.motif,
                reply_to: this.email,
                message: this.msgCtnt
            }

            emailjs.send('service_i8ozab9', 'template_0m21trr', templateParams, 'user_SIKb5iCTAtEvOuFbC9DGn')
                .then((response) => {
                    if (response.status == 200) {

                        this.snckBar = {
                            visible: true,
                            category: "success",
                            msg: "Votre demande a été transmise",
                            timeout: 1500
                        }
                    }
                }, function (err) {
                    console.log('FAILED...', err);
                });
        }
    },
    computed: {
        snckBar: {
            get() {
                return this.$store.getters.getSnack;
            },
            set(nVal) {
                this.$store.dispatch('setSnack', nVal)
            }
        }
    }
}
</script>

<style scoped>
.mv-tfield {
    min-width: 300px;
}

.vtxt {
    font-size: 14pt;
}

.ribon {
    background: rgba(112, 189, 205, 1);
}

.mainRow {
    background: rgba(242, 238, 229, 1);
}

.lttleHead {
    font-size: 16pt;
    color: rgb(65, 65, 65);
    margin-bottom: 15px;
}

.divider {
    color: rgb(105, 105, 105);
    width: 70%;
    margin: 20px auto;
    overflow: hidden;
    text-align: center;
    line-height: 1.2em;
}

.divider:before,
.divider:after {
    content: "";
    vertical-align: middle;
    display: inline-block;
    width: 50%;
    border-bottom: 2px dotted #ccc;
    margin: 0 2% 0 -55%;
}

.divider:after {
    margin: 0 -55% 0 2%;
}
</style>