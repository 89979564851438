<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="pa-0 ma-0 ribon">
      <v-col>
        <h1>Les services</h1>
      </v-col>
    </v-row>
    <v-row class="pa-0 mx-0 mt-6 mb-3" justify="center">
      <v-col lg="3" md="6" sm="10" xs="12">
        <v-card class="ccard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4"> mdi-message-bulleted </v-icon>
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Conseils</h2>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
              On ne s'engage pas à la légére, nous avons besoin, vous comme
              nous, d'être sûr de nos choix, c'est pourquoi nous prennons le
              temps ensemble d'analyser vos besoins.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3" md="6" sm="10" xs="12">
        <v-card class="ccard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4"> mdi-security-network </v-icon>
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Installation sécurisée</h2>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
              Un chantier propre, des procédures adaptées, nous mettons tout en
              oeuvre pour garantir une intervention 100% sécurisée.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3" md="6" sm="10" xs="12">
        <v-card class="ccard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4"> mdi-road-variant </v-icon>
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Assistance 24/7</h2>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
              Parce que nous savons que votre vie ne s'arréte pas à notre
              départ, 7 jours sur 7, 24h sur 24, nous assurons la maintenance
              express des équipements que nous posons pour vous.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- -- -- - -->
    <!-- -- -- - -->
    <!-- LIGNE 2 -->
    <v-row class="pa-0 mx-0 mt-3 mb-6" justify="center">
      <v-col lg="3" md="6" sm="9" xs="12">
        <v-card class="cncard elevation-0">
          <v-img
            :src="require('@/assets/img/Chateau-Arberats-600.png')"
          >
          </v-img>
        </v-card>
      </v-col>

      <v-col lg="3" md="6" sm="9" xs="12">
        <v-card class="cncard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Exemples</h2>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
              <strong>Eclairage</strong><br>
              - Résidentiels, Industriels, Intérieur & Extérieurs (jardins, parkings, etc ...)
            </p>
            <p class="parag">
                <strong>Electricité Générale</strong><br>
                - Industrie, tertiaire, Domestique
            </p>
            <p class="parag">
                <strong>Installation de <u>bornes éléctriques</u></strong><br>
                - Ci-contre à droite, l'une de nos nombreuses installation de bornes électriques.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3" md="6" sm="9" xs="12" >
        <v-card class="cncard elevation-0 ">
          <v-img
            :src="require('@/assets/img/schneider_evlink.jpg')"
          >
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.ribon {
  background: rgba(112, 189, 205, 1);
}
.ccard {
  height: 250px;
}
.cncard {
  height: 370px;
}
.parag {
  min-width: 250px;
  text-align: justify;
  font-size: 14pt;
}
</style>