<template>
  <v-app>
    <!-- TEST -->
    <!-- <NavBar /> -->
    <NavBar2 />
    <v-main>
      <router-view/>
    </v-main>
    <AppMsg />
    <Footer />
  </v-app>
</template>

<script>
import NavBar2 from '@/components/NavBar2.vue'
import Footer from '@/components/Footer.vue'
import AppMsg from '@/components/AppMsg.vue'

export default {
  name: 'App',
   components: {
    NavBar2,
    Footer,
    AppMsg
  },
  data: () => ({
    items: [
      { title: "L'entreprise" },
      { title: 'Les services' }
    ],
  }),
};
</script>
