<template>
  <v-container fluid class="pa-0 ma-0 main-row">
    <!-- HEADER -->
    <v-row class="pa-0 ma-0 ribon">
      <v-col>
        <h1>Les Bornes de recharge Schneider Electric</h1>
      </v-col>
    </v-row>
    <!-- Avant-propos -->
    <v-row justify="center" class="pa-0 ma-0">
      <v-col lg="6" md="8" sm="10" xs="12" align="center">
        <p style="font-size: 16pt;">
          Il existe de nombreuses configurations de bornes électriques différentes.
          Sur cette paghe nous souhaitons vous en présenter un échantillons représentatif.
        </p>
      </v-col>
    </v-row>
    <!-- LIGNE 1 -->
    <v-row class="pa-0 mx-0 mt-6 mb-3" justify="center">
      <v-col lg="5" md="8" sm="10" xs="12">
        <v-card class="mcard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4">mdi-home</v-icon>
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">
                Bornes de recharge à domicile
              </h2>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
                En installant une borne de recharge à votre domicile, 
                vous disposez d’une infrastructure spécifiquement conçue à cet usage.
                Vous vous protégez, ainsi que votre véhicule et l’ensemble 
                de vos équipements électriques, contre tout risque lié à l’électricité. 
                De plus, la borne Schneider Electric vous permet d’améliorer vos conditions 
                de recharge tout en maîtrisant vos consommations énergétiques.
            </p>
            <v-img
                class="rad"
                alt="Renault Zoe maison CEFED"
                :src="require('@/assets/img/electric-car-gfc3525984_640.jpg')"
            >
            </v-img>
            <p class="parag">
              Le coffret de recharge pour le particulier :
              sécurisé, performant, robuste et simple à utiliser
              Le coffret de recharge domestique de Schneider Electric est une offre packagée qui permet au particulier de disposer à son domicile, 
              à réception de sa voiture, d’une borne de recharge sécurisée, performante, robuste et simple à utiliser.
              <br><br>
              L’offre comprend :
              <br>
              la fourniture d’un matériel spécifiquement conçu, testé et validé par Schneider Electric, 
              spécialiste mondial de la gestion de l’énergie.
              Sa mise en œuvre par un installateur qualifié recommandé par Schneider Electric, 
              situé près de chez vous.
              Celui-ci vérifiera l’état de l’installation électrique existante 
              et son dimensionnement par rapport aux autres charges. 
              Il vous conseillera sur son éventuelle mise en conformité, 
              sur les possibilités d’optimiser vos consommations d’énergie, 
              sur la nécessité d’envisager une protection foudre (en fonction de la région et du risque kéraunique).
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="3" md="4" sm="10" xs="12">
        <GammeBorne />
      </v-col>
    </v-row>
    <v-row justify="center" class="pa-0 mx-0 mt-6 mb-3">
      <v-col lg="8" md="12" sm="10">
        <v-card>
          <v-row justify="center">
            <v-col lg="7" md="7" sm="7" xs="12">
              <v-card-text>
                <span class="parag">
                  <v-btn class="success mb-3">
                    Labels et certifications
                  </v-btn> 
                <br>
                  Notre entreprise est labélisée EV READY/ZE READY 
                  par les marques schneider et Hagger et agrée Greenup par Legrand.
                </span>
              </v-card-text>
              <v-card-text>
                <span class="parag">
                  <v-btn class="success mb-3">
                    Fourniture et pose
                  </v-btn> 
                <br>
                  Fourniture et pose de bornes de recharge intérieures 
                  et extérieures pour particuliers, collectivité et industries .
                </span>
              </v-card-text>
              <v-card-text>
                <span class="parag">
                  <v-btn class="success mb-3">
                    Audit
                  </v-btn> 
                <br>
                  Afin d’être certain que votre VE acceptera de recharger, 
                  nous réalisons un audit de votre installation électrique puis 
                  un devis pour la pose d’une borne adaptée à vos besoins.
                </span>
              </v-card-text>
              <hr>
              <v-btn
                class="mx-3 mt-6 info"
                href="/files/Bornes-EV-Schneider-Guide-Produits.pdf" 
                download
              >
                <v-icon class="mr-3">
                  mdi-file-document
                </v-icon>
                Guide bornes électriques Schneider
              </v-btn>
            </v-col>
            <v-col lg="5" md="5" sm="4" xs="12" align="center">
              <v-img
                alt="CEFED ZE Ready EV Ready"
                :src="require('@/assets/img/ZE-Ready-EV-Ready.png')"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- -- -- - -->
    <!-- LIGNE 2 -->
    <!-- -- -- - -->
    <v-row class="pa-0 mx-0 mt-6 pb-6" justify="center">
        <v-col lg="5" md="7" sm="10" xs="12">
            <v-card>
              <v-toolbar class="white--text elevation-0" color="grey darken-1">
                <v-icon dark large class="mr-4">mdi-car-electric</v-icon>
                <v-toolbar-title>
                  <h2 style="font-variant: small-caps">Les véhicules électriques</h2>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
              <p class="parag">
                Le succès du véhicule électrique passe par la mise en place d’infrastructures 
                de recharge sûres et disponibles, permettant aux utilisateurs d’accéder à l’énergie, 
                partout, à tout moment, et de disposer ainsi de véhicules toujours opérationnels.
                <br><br>
                Des sociétés renommées comme Schneider Electric, 
                Hagger et Legrand proposent des solutions de recharge pour tout type d’utilisateur. 
                Ces solutions se distinguent par leur niveau de sécurité 
                et par leurs fonctions d’optimisation énergétique, 
                favorisant l’intégration de l’infrastructure de recharge 
                dans les installations existantes, comme dans les réseaux intelligents (smart grids). Les architectures “en grappes”, adaptées aux flottes de véhicules, donnent, accès à des niveaux de gestion évolués, s’apparentant ainsi à un véritable outil de supervision.
                <br><br>
                De plus, CEFED en partenariat avec ces 3 sociétés propose à ses clients 
                des services personnalisés pour définir l’infrastructure la mieux adaptée 
                à leur besoin : bilan technique, financier, énergétique et/ou environnemental, 
                dimensionnement de l’installation, management de l’énergie, etc. 
                <br><br>
                Cette prestation complète fait de CEFED, spécialiste agréé de la pose d’infrastructure 
                de recharge, un partenaire unique pour 
                tous ceux qui souhaitent adopter le véhicule électrique.
              </p>
              </v-card-text>
            </v-card>
        </v-col>
        <v-col lg="3" md="5" sm="10" xs="12">
        <v-card class="ccard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4">mdi-star</v-icon>
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Les avantages avec CEFED</h2>
            </v-toolbar-title>
          </v-toolbar>
          <v-img
            height="125"
            alt="CEFED tableau electrique"
            :src="require('@/assets/img/electric-gb3cb6744b_640.jpg')"
          >
          </v-img>
          <v-card-text>
            <p class="parag">
              Diagnostic possible de votre installation électrique par un électricien qualifié : mise en sécurité, protection contre la foudre, …
              <br><br>
              Conseils et préconisations en termes de solutions d’économie d’énergie ou de domotique
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GammeBorne from "@/components/Bornes/Gamme.vue"


export default {
  components: {
    GammeBorne
  },
  data() {
    return {}
  }
};
</script>

<style scoped>
.rad {
  border-radius: 5px;
  margin-bottom: 10px;
}
.main-row {
  background: rgba(242, 238, 229, 1);
}
.ribon {
  background: rgba(112, 189, 205, 1);
}
.mcard {
  /* height: 550px; */
}
.ccard {
  /* height: 350px; */
}
.cncard {
  height: 350px;
}
.parag {
  text-align: justify;
  font-size: 14pt;
}
</style>