import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//-DEBUT > ROUTES DU MENU
//-Partie 1
import Entreprise       from '../views/Entreprise.vue'
import Services         from '../views/Services.vue'
import Tarifs           from '../views/Tarifs.vue'
//-Partie 2
import EG               from '../views/ElectriciteGenerale.vue'
//-Partie 3
import BornesRecharge   from '../views/BornesRecharge.vue'
import TarifsBornes     from '../views/Prix-bornes.vue'
import FAQ              from '../views/faq.vue'
//-Partie 4
import MentionsLegales  from '../views/MentionsLegales.vue'
import Contact          from '../views/Contact.vue'
//-FIN > ROUTES DU MENU

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/entreprise',
    name: 'Entreprise',
    component: Entreprise
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/tarifs',
    name: 'Tarifs',
    component: Tarifs
  },
  {
    path: '/eg',
    name: 'Electricité Générale',
    component: EG
  },
  {
    path: '/bornesRecharge',
    name: 'Les bornes de recharge',
    component: BornesRecharge
  },
  {
    path: '/tarifs-bornes',
    name: 'Tarifs des bornes de recharge',
    component: TarifsBornes
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/mentions-legales',
    name: 'Mentions légales',
    component: MentionsLegales
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
