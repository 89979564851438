<template>
  <v-container fluid class="pa-0 contain lighten-3">
        <div class="shadow">
            <v-parallax 
                alt="CEFED au pied des pyrenees" 
                :src="require('@/assets/img/CEFED-Panoramique-Reduced.png')">
                <v-row justify="center">
                    <v-col lg="6" md="6" sm="10" xs="10" align="center">
                        <v-card class="cardColor mt-12 pa-12">
                            <h1>CEFED à votre service au pied des pyrénées</h1>
                        </v-card>
                    </v-col>
                </v-row>
            </v-parallax>
            <!-- <v-img
            :src="require('@/assets/img/CEFED-Panoramique-Reduced.png')"
            class="customA"
            > -->
                <!-- <v-row align="center">
                    <v-col lg="2" md="1" sm="0" align="center">
                    </v-col>
                    <v-col lg="4" md="5" sm="6" align="center">
                        <v-card class="cardColor mt-12 pa-12">
                            <h1>CEFED à votre service ...</h1>
                        </v-card>
                        
                    </v-col>
                    <v-col lg="4" md="5" sm="6" align="center">
                        <v-card class="cardColor mt-12 pa-12">
                            <h1>... au pied des pyrénées</h1>
                        </v-card>
                    </v-col>
                    <v-col lg="2" md="1" sm="0" align="center">
                    </v-col>
                </v-row> -->
            <!-- </v-img> -->
        </div>
  </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>
.contain {
    background : rgba(64, 64, 57, 0.4);
}
.customA {
    position: relative;
    /*
    border-top: 3px solid grey;
    border-bottom: 3px solid grey;
    */
}
.shadow {
  overflow: hidden;
  position: relative;
}

.shadow:before {
  bottom: 0;
  box-shadow: inset 3px 6px 25px 1px #000;
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}
.cardColor {
   background-color: rgba(255, 255, 255, 0.5) !important;
   border-color: white !important;
 }
</style>