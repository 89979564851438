<template>
  <v-bottom-sheet
    v-model="cookiesRules.dsply"
    inset
  > 
    <v-sheet
        class="pa-3"
    >
      <v-row
        full-height
        justify="center"
      >
        <v-col cols="9">
            <h3>Politique de gestion des cookies sur ce site web</h3>
            <hr>
            <br>
            <p class="emph">
                Ce site web utilise un cookie nécessaire à la navigation sur la version du site chargé par l'utilisateur <u>uniquement</u>.
            </p>
            <p class="emph">
                Aucune donnée n'est exploitée concernant les activités des utilisateurs et ou traitée de manière centralisée.
            </p>
            <p class="emph">
                Les informations transmises à l'aide du formulaire de contact sont directement adressées à la SARL CEFED ainsi qu'au webmaster par l'intermédiaire du service de messagerie utilisé.
            </p>
        </v-col>
        <v-col cols="3">
            <v-card>
                <v-img
                    :src="require('@/assets/img/markus-spiske-computer-datas-unsplash.jpg')"
                >
                </v-img>
            </v-card>
        </v-col>
      </v-row>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
    data() {
        return {}
    },
    computed: {
      cookiesRules: {
        get() {
            return this.$store.getters.getCookiesRules;
        },
        set(nVal) {
            this.$store.commit('setCookiesRules', nVal)
        }
      }
    }
}
</script>

<style scoped>
.emph {
    font-size: 16pt;
}
</style>