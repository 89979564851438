<template>
    <v-container fluid class="footer pt-8">
        <v-row class="pa-0 ma-0" justify="center">
            <v-col lg="5" md="7" sm="10" xs="12" class="pa-0 ma-0" align="center">
                <v-card>
                    <v-card-title>CEFED</v-card-title>
                    <hr style="padding-left: 50px;">
                    <v-row class="pa-0 ma-0">
                        <!-- GAUCHE -->
                        <v-col xl="5" lg="5" md="5" sm="6" xs="12" align="left">
                            <v-icon class="mr-3">mdi-cellphone-basic</v-icon>
                            <span class="emp">
                                <a href="tel:+33642731732">
                                    <v-btn small>Afficher le numéro</v-btn>
                                </a>
                            </span>
                            <br>
                            <v-icon class="mr-3">mdi-phone-classic</v-icon>
                            <span class="emp">

                                <a href="tel:+33562454223">
                                    <v-btn small>Afficher le numéro</v-btn>
                                </a>
                            </span>
                            <br>
                            <v-icon class="mr-3">mdi-mailbox</v-icon>
                            <span class="emp">17 rue de l'église</span>
                            <br>
                            <span class="emp">65380 Hibarette</span>
                            <br>
                            <v-icon class="mr-3">mdi-email</v-icon>
                            Par email en replissant le formulaire de contact
                        </v-col>
                        <v-col xl="7" lg="7" md="7" sm="6" xs="12">
                            <v-img alt="renault zoe borne de recharge" class="imgInCard"
                                :src="require('@/assets/img/car-ga2ceb00b8_1920.png')">
                            </v-img>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col lg="3" align="center">
                <v-card class="cardCalc elevation-0">
                    <v-img alt="logo voiture électrique" class="dupliImg" :src="require('@/assets/img/picto-car-elec.png')">
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
        <hr>
        <v-row justify="center" class="pa-0 ma-0">
            <v-col lg="4" md="6" sm="8" xs="12" align="center">
                <v-btn dark text to="mentions-legales">
                    Mentions Légales
                </v-btn>
                |
                <v-btn dark text @click="displayCookieRules()">
                    Cookies
                </v-btn>
            </v-col>
        </v-row>
        <CookiesRules />
    </v-container>
</template>

<script>
import CookiesRules from "@/components/Cookies/Cookies-rules.vue"

export default {
    components: {
        CookiesRules
    },
    data() {
        return {

        }
    },
    methods: {
        displayCookieRules() {
            console.log("Display Cookies Rules")
            this.cookiesRules.dsply = true;
        }
    },
    computed: {
        cookiesRules: {
            get() {
                return this.$store.getters.getCookiesRules;
            },
            set(nVal) {
                this.$store.commit('setCookiesRules', nVal)
            }
        }
    }
}
</script>

<style scoped> .footer {
     background-color: rgb(112, 189, 205);
 }

 .dupliImg {
     width: 300px;
 }

 .emp {
     font-size: 16pt;
 }

 .cardCalc {
     background-color: rgba(255, 255, 255, 0) !important;
     border-color: white !important;
 }
</style>