<template>
  <v-container fluid class="pa-0 ma-0 main-row">
    <!-- HEADER -->
    <v-row class="pa-0 ma-0 ribon">
      <v-col>
        <h1>Electricité générale</h1>
      </v-col>
    </v-row>
    <!-- Avant-propos -->
    <v-row justify="center" class="pa-0 ma-0">
      <v-col lg="6" md="8" sm="10" xs="12" align="center">
        <p style="font-size: 16pt;">
          Sont listés ci-dessous les principaux travaux 
          d'électricité générales que nous sommes en mesure de réaliser.
        </p>
      </v-col>
    </v-row>
    <!-- LIGNE 1 -->
    <v-row class="pa-0 mx-0 mt-6 mb-3" justify="center">
      <v-col lg="4" md="6" sm="10" xs="12">
        <v-card class="ccard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4">mdi-autorenew</v-icon>
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Rénovations partielles et totales</h2>
            </v-toolbar-title>
          </v-toolbar>
          <v-img
            height="125"
            alt="CEFED rénovation de maison et appartement"
            :src="require('@/assets/img/renovation-g860ec0e05_640 - Copie.jpg')"
          >

          </v-img>
          <v-card-text>
            <p class="parag">
                Cette opération permet de rénover un logement 
                sans tomber sous les caprices de confort 
                et d’accessibilité imposés par la norme NF C 15100 
                et donc de limiter les coûts des travaux.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="4" md="6" sm="10" xs="12">
        <v-card class="ccard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4">mdi-fire-truck</v-icon>
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Dépannages d'urgence</h2>
            </v-toolbar-title>
          </v-toolbar>
          <v-img
            height="125"
            alt="CEFED dépannage électricité"
            :src="require('@/assets/img/fire-gce373e1ee_640.jpg')"
          >
          </v-img>
          
          <v-card-text>
            <p class="parag">
              Un chantier propre, des procédures adaptées, nous mettons tout en
              oeuvre pour garantir une intervention 100% sécurisée.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- -- -- - -->
    <!-- LIGNE 2 -->
    <!-- -- -- - -->
    <v-row class="pa-0 mx-0 mt-6 mb-3" justify="center">
      <v-col lg="4" md="6" sm="10" xs="12">
        <v-card class="ccard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4">mdi-security-network</v-icon>
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Mise en sécurité des logements</h2>
            </v-toolbar-title>
          </v-toolbar>
        <v-img
            height="125"
            alt="CEFED tableau électrique"
            :src="require('@/assets/img/electric-gb3cb6744b_640.jpg')"
          >
          </v-img>
          <v-card-text>
            <p class="parag">
                Cette opération est souvent réalisée pour le locatif, 
                avant transactions immobilières ou bien pour des personnes 
                souhaitant être en sécurité avec le plus petit budget possible.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="4" md="6" sm="10" xs="12">
        <v-card class="ccard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4">mdi-outdoor-lamp</v-icon>
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Remplacement des éclairages</h2>
            </v-toolbar-title>
          </v-toolbar>
          <v-img
            height="125"
            alt="CEFED changement des éclairages"
            :src="require('@/assets/img/light-bulbs-gea92fd32d_640.jpg')"
          >
          </v-img>
          <v-card-text>
            <p class="parag">
                Etudes de vos besoins aves optimisation des consommations sur ce poste réputé énergivore.
                Eclairages spécifiques (jardins, cuisines professionnelles, ambiances explosives….)
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- -- -- - -->
    <!-- LIGNE 3 -->
    <!-- -- -- - -->
    <v-row class="pa-0 mx-0 mt-6 pb-6" justify="center">
      <v-col lg="4" md="6" sm="10" xs="12">
        <v-card class="ccard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4">mdi-certificate</v-icon>
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Domaines de compétences</h2>
            </v-toolbar-title>
          </v-toolbar>
          <div style="width: 100%; text-align: center;" class="py-3">
            <v-icon color="yellow" large>mdi-star</v-icon>
            <v-icon color="yellow" large>mdi-star</v-icon>
            <v-icon color="yellow" large>mdi-star</v-icon>
            <v-icon color="yellow" large>mdi-star</v-icon>
            <v-icon color="yellow" large>mdi-star</v-icon>
          </div>
          <v-card-text>
            <p class="parag">
                Electricité générale, réseaux de communication, domotique, 
                automatismes, chauffage, éclairages spécifiques, ventilation 
                (y compris double flux).
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="4" md="6" sm="10" xs="12">
        <v-card class="ccard">
          <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4">mdi-certificate</v-icon>
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Domaines d'activité</h2>
            </v-toolbar-title>
          </v-toolbar>
          <div style="width: 100%; text-align: center;" class="py-3">
            <v-icon color="yellow" large>mdi-star</v-icon>
            <v-icon color="yellow" large>mdi-star</v-icon>
            <v-icon color="yellow" large>mdi-star</v-icon>
            <v-icon color="yellow" large>mdi-star</v-icon>
            <v-icon color="yellow" large>mdi-star</v-icon>
          </div>
          <v-card-text>
            <p class="parag">
                <strong>Installation individuelles et collectives</strong>
                <br><br>
                Installations tertiaires 
                (commerces, bureaux, administrations, collectivités…), 
                <br><br>
                <strong>installations industrielles</strong>.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.main-row {
    background: rgba(242, 238, 229, 1);
}
.ribon {
  background: rgba(112, 189, 205, 1);
}
.ccard {
  height: 325px;
}
.cncard {
  height: 350px;
}
.parag {
  text-align: justify;
  font-size: 14pt;
}
</style>