<template>
    <div>
        <!-- color="rgba(242, 238, 229, 1)" -->
        <v-app-bar
            app
            color="white"
        >
        <v-spacer
            class="hidden-sm-and-down"
        ></v-spacer>
        <v-img
            contain
            width="130"
            height="50"
            src="@/assets/img/logo-cefed-av-txt.png"
        ></v-img>
        <v-spacer
            class="hidden-md-and-up"
        ></v-spacer>
        <!--  -->
        <!-- MENU RESPONSIVE ECRAN LARGE -->
        <div class="hidden-md-and-down">
            <v-btn
                icon
                to="/"
                color="grey darken-3"
            >
                <v-icon large>mdi-home</v-icon>
            </v-btn>
            <!-- BOUTON MENU 1 -->
            <v-menu 
                transition="slide-x-transition"
                offset-y >
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    text
                    class="mr-3 black--text"
                    v-bind="attrs"
                    v-on="on"
                >
                    Accueil
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                </template>
                <v-list>
                    <v-list-item
                    v-for="(item, i) in menuItems"
                    :key="i"
                    :to="item.route"
                    >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ item.title }}
                    </v-list-item-title>
                    </v-list-item>
                </v-list>
                <!-- <v-list>
                    <v-list-item
                        v-for="(item, index) in menuItems"
                        :key="index"
                        class="pa-1 ma-0"
                    >
                        <v-list-item-icon v-if="item.icon != null">
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-btn  :to="item.route" text>{{ item.title }}</v-btn>
                    </v-list-item>
                </v-list> -->
            </v-menu>
            <!-- BOUTON MENU 2 -->
            <v-btn 
                text
                class="black--text mr-2"
                to="EG"
            >
                <v-icon class="mr-2">mdi-power-plug</v-icon>
                Electricité générale
            </v-btn>
            <!-- BOUTON MENU 3 -->
            <v-menu offset-y >
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    text
                    class="mr-3 black--text"
                    v-bind="attrs"
                    v-on="on"
                >   
                    <v-icon class="mr-2">mdi-car-electric</v-icon>
                    Bornes électriques
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                </template>
                <v-list>
                    <v-list-item
                    v-for="(item, i) in menuItemsBornes"
                    :key="i"
                    :to="item.route"
                    >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ item.title }}
                    </v-list-item-title>
                    </v-list-item>
                </v-list>

                <!-- <v-list>
                <v-list-item
                    v-for="(item, index) in menuItemsBornes"
                    :key="index"
                    class="pa-1 ma-0"
                >
                    <v-btn  text>{{ item.title }}</v-btn>
                </v-list-item>
                </v-list> -->
            </v-menu>
            <!-- BOUTON MENU 4 -->
            <v-btn 
                text
                class="black--text mr-2"
                to="contact"
            >
                <v-icon class="mr-1">mdi-email</v-icon>
                Contact
            </v-btn>
        </div>

        <!-- MENU RESPONSIVE SMALL DEVICE -->
        <div class="hidden-lg-and-up">
        <!-- BOUTON ACCUEIL REPETITION -->
        <v-btn
            dark icon 
            class="mr-3 grey darken-2"
            to="/"
        >
            <v-icon large>mdi-home</v-icon>
        </v-btn>     
        <!-- MINI MENU -->
        <v-menu
            left
            bottom
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                dark icon class="grey darken-2"
                v-bind="attrs"
                v-on="on"
                >
                <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-list-item
                v-for="(item, i) in smallMenuItems"
                :key="i"
                :to="item.route"
                >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    {{ item.title }}
                </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        </div>
        <v-spacer
            class="hidden-sm-and-down"
        ></v-spacer>
    </v-app-bar>
  </div>
</template>

<script>
export default {
    data() {
        return {
            menuItems: [
                { 
                    title: "L'entreprise",
                    icon: 'mdi-domain',
                    route: "entreprise"
                },
                { 
                    title: 'Les services',
                    icon: 'mdi-toolbox-outline',
                    route: "services"
                },
                { 
                    title: 'Les tarifs',
                    icon: 'mdi-currency-eur',
                    route: "tarifs"
                }
            ],
            smallMenuItems: [
                { 
                    title: "Accueil",
                    icon: 'mdi-home',
                    route: "/"
                },
                { 
                    title: "Les services",
                    icon: 'mdi-domain',
                    route: "services"
                },
                { 
                    title: "L'entreprise",
                    icon: 'mdi-domain',
                    route: "entreprise"
                },
                { 
                    title: 'Les tarifs',
                    icon: 'mdi-currency-eur',
                    route: "/tarifs"
                },
                { 
                    title: 'Les bornes électriques',
                    icon: 'mdi-ev-station',
                    route: "/bornesRecharge"
                },
                { 
                    title: 'Electricité générale',
                    icon: 'mdi-power-plug',
                    route: "/EG"
                },
                { 
                    title: 'Contact',
                    icon: 'mdi-email',
                    route: "/contact"
                }
            ],
            menuItemsBornes: [
                { 
                    title: "La gamme de bornes",
                    icon: 'mdi-ev-station',
                    route: "/bornesRecharge"
                },
                { 
                    title: 'Les prix',
                    icon: 'mdi-currency-eur',
                    route: "/tarifs-bornes" 
                },
                { 
                    title: 'FAQ',
                    icon: 'mdi-frequently-asked-questions',
                    route: "/faq"
                }
            ],
        }
    }
}
</script>

<style scoped>

</style>