<template>
    <v-card class="mcard">
        <v-toolbar class="white--text elevation-0" color="grey darken-1">
            <v-icon dark large class="mr-4">mdi-ev-station</v-icon>
            <v-toolbar-title>
                <h2 style="font-variant: small-caps">La gamme</h2>
            </v-toolbar-title>
        </v-toolbar>
        <!-- <v-img
            alt="Bornes de recharge EV Link Schneider Electric"
            :src="require('@/assets/img/EV-Link-Schneider-Electric.png')"
        >
        </v-img> -->
        <v-carousel height="442">
            <v-carousel-item
                v-for="(item,i) in topCarouselItems"
                :key="i" :src="item.img"
                contain
                alt="Gamme de bornes de recharges Legrand et Schneider Electric"
                reverse-transition="fade-transition"
                transition="fade-transition"
            ></v-carousel-item>
        </v-carousel>
        <v-card-text>
            Ci-dessus la gamme de bornes de recharge de chez Schneider electric
        </v-card-text>
        <!-- <hr> -->
        <v-carousel>
            <v-carousel-item
                v-for="(item,i) in carouselItems"
                :key="i" :src="item.img"
                reverse-transition="fade-transition"
                transition="fade-transition"
            ></v-carousel-item>
        </v-carousel>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            topCarouselItems: [
                {
                    name: "Cable Schneider Electric",
                    img: require("@/assets/img/EV-Link-Schneider-Electric.png")
                },
                {
                    name: "Prise Green UP",
                    img: require("@/assets/img/Prise-Green-Up-Flou-600.png")
                }
            ],
            carouselItems: [
                {
                    name: "Cable Schneider Electric",
                    img: require("@/assets/img/slideshow-bornes/ze-cable.jpg")
                },
                {
                    name: "Borne de charge Schneider Electric",
                    img: require("@/assets/img/slideshow-bornes/ze-charge.jpg")
                },
                {
                    name: "Charge schneider",
                    img: require("@/assets/img/slideshow-bornes/ze-pack-renault.jpg")
                },
                {
                    name: "Charge schneider",
                    img: require("@/assets/img/slideshow-bornes/ze-prise.jpg")
                },
                {
                    name: "Charge schneider",
                    img: require("@/assets/img/slideshow-bornes/ze-public.jpg")
                },
            ]
        }
    }
}
</script>

<style>

</style>