<template>
  <v-container class="pa-0 ma-0" fluid>
      <v-row justify="center" class="pa-0 ma-0 mainRow">
          <v-col lg="6" md="8" sm="10" xs="12" class="pa-4" align="center">
              <h1 class="divider">Ils nous font confiance</h1>
              <v-img
                width="700"
                class="rad mb-6 elevation-3"
                alt="partenaires cefed"
                src="@/assets/img/partenaires-n.png"
              >
              </v-img>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {
}
</script>

<style scoped>
.mainRow {
    background-color: rgba(242, 238, 229, 1);
}
.rad {
  border-radius: 25px ;
}
.divider{
    color:rgb(105, 105, 105);
    width:70%;
    margin:20px auto;
    overflow:hidden;
    text-align:center;   
    line-height:1.2em;
}

.divider:before, .divider:after{
    content:"";
    vertical-align:middle;
    display:inline-block;
    width:50%;
    border-bottom:2px dotted #ccc;
    margin:0 2% 0 -55%;
}
.divider:after{
    margin:0 -55% 0 2%;
}
</style>