<template>
  <v-container class="pa-0 ma-0" fluid>
    <SlideShow />
    <BGround />
    <Trusted />
  </v-container>



</template>

<script>
  import SlideShow from '@/components/Home/slideShow.vue'
  import BGround from '@/components/Home/background.vue'
  import Trusted from '@/components/Home/trusted.vue'

  export default {
    name: 'Home',

    components: {
      SlideShow,
      BGround,
      Trusted
    },
  }
</script>
