<template>
    <v-container fluid>
        <v-row justify="center" class="main-row py-12">
            <!-- LIGNE 1 - Images de fond -->
            <v-col cols="12" >
                <div>
                    <!-- <v-img
                        style="position: absolute; left: -15%; top: 0; width: 1000px;"
                        src="@/assets/img/car-ga2ceb00b8_1920.png"
                    >
                    </v-img> -->
                </div>
            </v-col>
            <!-- LIGNE 2 -->
            <v-col lg="6" md="7" sm="10" xs="12" align="center">
                <v-carousel 
                    cycle
                    v-model="model"
                    class="rounded"
                    hide-delimiters>
                    <!-- CAROUSEL ITEMS -->
                    <v-carousel-item
                        v-for="(item, i) in cards"
                        :key="i"
                    >
                    <v-card class="sshow pb-12">
                        <v-toolbar 
                            :color="item.color"
                            large class=" elevation-0 white--text">
                            <v-icon dark large class="mr-3">
                                {{ item.tbLogo }}
                            </v-icon>
                            <v-toolbar-title>
                                <h1>{{ item.mainTitle }}</h1>
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-row class="pa-0 px-12 ma-0" justify="center">
                            <v-col cols="12" align="start">
                                <h3>{{ item.mainSubTitle }}</h3>
                            </v-col>
                        </v-row>
                        <v-row class="pa-0 px-12 ma-0" justify="center">
                            <v-col cols="12">
                                <v-card class="" color="grey lighten-3" >
                                    <v-row class="pa-0 ma-0" justify="center">
                                        <v-col lg="5" md="5" sm="5" xs="12">
                                            <v-progress-circular
                                            :rotate="360"
                                            :size="150"
                                            :width="25"
                                            :value="item.progressV"
                                            color="primary"
                                            >
                                                <span  style="font-size: 18pt;" >{{ item.progressV }}%</span>
                                            </v-progress-circular>
                                        </v-col>
                                        <v-col lg="7" md="7" sm="7" xs="12" class="pa-3" align="start">
                                            <h2>{{ item.titleText }}</h2>
                                            <p>{{ item.subTitleText }}</p>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                    </v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>        
    </v-container>

</template>

<script>
export default {
    data() {
        return {
            stp: 1,
            model: 0,
            cards: [{
                    step: 1,
                    progressV: 50,
                    color: "rgb(57, 100, 169)",
                    tbLogo: "mdi-handshake",
                    mainTitle: "Professionnel ou Particuliers",
                    mainSubTitle: "CEFED : L'électricité maîtrisée",
                    titleText: `Des offres "électricité" adaptées`,
                    subTitleText: "Mise aux normes, rénovation, dépannage d'urgence, éclairage...",
                    routeBtnA: "",
                    routeBtnB: "",
                },
                {
                    step: 2,
                    progressV: 65,
                    color: "rgb(179, 103, 0)",
                    tbLogo: "mdi-ev-station",
                    mainTitle: "Bornes électriques",
                    mainSubTitle: "Une gamme étudiée pour tous vos besoins",
                    titleText: "Controle total",
                    subTitleText: "Maitrisez l'intégralité de vos consommations et partez sans surprises",
                    routeBtnA: "",
                    routeBtnB: "",
                },
                {
                    step: 3,
                    progressV: 75,
                    tbLogo: "mdi-leaf",
                    color: "rgb(97, 180, 87)",
                    mainTitle: "Environnement",
                    mainSubTitle: "Chaque geste compte",
                    titleText: "Recyclum",
                    subTitleText: "Nous nous occupons de vos divers polluants éléctriques.",
                    routeBtnA: "",
                    routeBtnB: "",
                }
            ],
            valueA: 50,
            valueB: 65,
            valueC: 75
        }
    },
    methods: {}
};
</script>

<style scoped>
.main-row {
    background-color: rgb(112, 189, 205);
    background-image: url('../../assets/img/car-gcac190a3f_1920.png');
    /* background-image: url('../../assets/img/car-ga2ceb00b8_1920.png'); */
}
.rounded {
    border-radius: 25px;
}
.sshow {
    border-radius: 15px;
}
/*
.v-stepper-items  {
    background: rgb(112, 189, 205, 1);
}
*/
stepColor {
    background: rgba(112, 189, 205, O.1) !important;
}
</style>