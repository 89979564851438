<template>
  <v-container fluid class="pa-0 ma-0 main-row">
    <!-- HEADER -->
    <v-row class="pa-0 ma-0 ribon">
      <v-col>
        <h2>
            Foire Aux Questions (FAQ)
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center" class="pa-0 ma-0">
      <v-col align="center" lg="4" md="4" sm="10" xs="12">
          <v-card height="150px" min-width="300" class="py-3">
                <h2>
                    <v-icon>mdi-information-outline</v-icon>
                    Besoin d'aide ?
                </h2>
                <v-card-text>
                    Un conseil ? Une etude de votre installation ? 
                    <br>
                    Nous sommes à votre écoute.
                </v-card-text>
          </v-card>
      </v-col>
      <v-col align="center" lg="4" md="4" sm="10" xs="12">
            <v-card height="150px" min-width="300" class="py-3">
                <h2>
                    <v-icon>mdi-chat-question-outline</v-icon>
                    Des questions ?
                </h2>
                <v-card-text>
                    Nous répondons à toutes vos questions, n'hésitez plus !
                </v-card-text>
            </v-card>
      </v-col>
    </v-row>
    <!-- LIGNE 1 -->
    <v-row class="pa-0 mx-0 mt-6 pb-6" justify="center">
      
      <v-col lg="8" md="8" sm="10" xs="12">
        <v-card class="mcard">
          <v-toolbar
            dense
            class="white--text elevation-0"
            color="grey darken-1"
          >
            <v-icon dark class="mr-4">mdi-arrow-right-thick</v-icon>
            <v-toolbar-title>
                <h3 style="font-variant: small-caps">
                  Une garantie de sécurité totale de la personne et du véhicule
                </h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
              Pour recharger votre véhicule en parfaite sécurité,
              votre installation doit être conforme aux normes en vigueur :
              elle doit notamment intégrer une protection différentielle
              pour vous protéger contre tout risque d’électrocution, une mise à la terre,
              une protection parafoudre dans les zones exposées.
              Il est donc recommandé d’installer une borne de recharge dédiée,
              spécifiquement conçue pour ce type d’usage.
            </p>
          </v-card-text>
          <v-toolbar
            dense
            class="white--text elevation-0"
            color="grey darken-1"
          >
            <v-icon dark class="mr-4">mdi-arrow-right-thick</v-icon>
            <v-toolbar-title>
              <h3 style="font-variant: small-caps">Suppression des risques de perturbation sur les autres équipements</h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
              Votre installation doit être correctement dimensionnée pour pouvoir supporter 
              une  recharge quotidienne sans nuire au bon fonctionnement 
              des autres appareils du logement (équipement de cuisson, lave linge, 
              ordinateur, TV, etc.). Votre installateur pourra vous renseigner 
              sur l’adéquation de votre abonnement EDF avec vos charges et, si besoin, 
              vous conseiller sur une solution de délestage.
            </p>
          </v-card-text>
          <v-toolbar
            dense
            class="white--text elevation-0"
            color="grey darken-1"
          >
            <v-icon dark class="mr-4">mdi-arrow-right-thick</v-icon>
            <v-toolbar-title>
              <h3 style="font-variant: small-caps">
                Une réduction du temps de recharge
              </h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
              Une installation adaptée vous permettra de gagner en confort et en rapidité. 
              Inutile d’immobiliser votre véhicule pendant une nuit entière pour le recharger 
              : quelques heures peuvent suffire pour qu’il soit disponible 
              avec une autonomie optimale.
            </p>
          </v-card-text>
          <v-toolbar
            dense
            class="white--text elevation-0"
            color="grey darken-1"
          >
            <v-icon dark class="mr-4">mdi-arrow-right-thick</v-icon>
            <v-toolbar-title>
              <h3 style="font-variant: small-caps">
                L’optimisation de vos consommations d’énergie
              </h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
                Des options vous permettent d’aller au bout de votre démarche environnementale en limitant vos consommations énergétiques
                <br>
                délestage pour éviter de dépasser la puissance souscrite auprès du fournisseur d’énergie
                lancement de la recharge lorsque l’énergie coûte moins cher et ne provient pas d’énergie fossile.
            </p>
          </v-card-text>
          <!--  -->
          <v-toolbar
            dense
            class="white--text elevation-0"
            color="grey darken-1"
          >
            <v-icon dark class="mr-4">mdi-arrow-right-thick</v-icon>
            <v-toolbar-title>
              <h3 style="font-variant: small-caps">
                Raccordement sur une prise standard : en dépannage uniquement
              </h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
                Les véhicules électriques sont livrés 
                avec un câble permettant le raccordement sur une prise standard.
                Celui-ci doit n’être utilisé qu’en dépannage :
                les prises standards ne sont pas destinées à être sollicitées pour une telle puissance 
                à fréquence régulière.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      carouselItems: [
        {
          name: "Cable Schneider Electric",
          img: require("@/assets/img/slideshow-bornes/ze-cable.jpg"),
        },
        {
          name: "Borne de charge Schneider Electric",
          img: require("@/assets/img/slideshow-bornes/ze-charge.jpg"),
        },
        {
          name: "Charge schneider",
          img: require("@/assets/img/slideshow-bornes/ze-pack-renault.jpg"),
        },
        {
          name: "Charge schneider",
          img: require("@/assets/img/slideshow-bornes/ze-prise.jpg"),
        },
        {
          name: "Charge schneider",
          img: require("@/assets/img/slideshow-bornes/ze-public.jpg"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.rad {
  border-radius: 5px;
  margin-bottom: 10px;
}
.main-row {
  background: rgba(242, 238, 229, 1);
}
.ribon {
  background: rgba(112, 189, 205, 1);
}
.cncard {
  height: 350px;
}
.parag {
  text-align: justify;
  font-size: 14pt;
}
</style>