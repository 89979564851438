import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    snackState: {
      msg: "",
      category: "",
      visible: false,
      timeout: 1500
    },
    cookiesRules: {
      dsply: false
    }
  },
  mutations: {
    setSnack (state, nSnackState) {
      state.snackState = nSnackState;
    },
    setCookiesRules (state, nSnackState) {
      state.snackState = nSnackState;
    }
  },
  actions: {
    setSnack (context, nVal) {
      context.commit('setSnack', nVal)
    },
    setCookiesRules (context, nVal) {
      context.commit('setCookiesRules', nVal)
    }
  },
  getters: {
    getSnack: state => {
      return state.snackState;
    },
    getCookiesRules: state => {
      return state.cookiesRules;
    }
  },
  modules: {
  }
})
