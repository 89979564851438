var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","color":"white"}},[_c('v-spacer',{staticClass:"hidden-sm-and-down"}),_c('v-img',{attrs:{"contain":"","width":"130","height":"50","src":require("@/assets/img/logo-cefed-av-txt.png")}}),_c('v-spacer',{staticClass:"hidden-md-and-up"}),_c('div',{staticClass:"hidden-md-and-down"},[_c('v-btn',{attrs:{"icon":"","to":"/","color":"grey darken-3"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-home")])],1),_c('v-menu',{attrs:{"transition":"slide-x-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 black--text",attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Accueil "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.menuItems),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.route}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1),_c('v-btn',{staticClass:"black--text mr-2",attrs:{"text":"","to":"EG"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-power-plug")]),_vm._v(" Electricité générale ")],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3 black--text",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-car-electric")]),_vm._v(" Bornes électriques "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.menuItemsBornes),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.route}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1),_c('v-btn',{staticClass:"black--text mr-2",attrs:{"text":"","to":"contact"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-email")]),_vm._v(" Contact ")],1)],1),_c('div',{staticClass:"hidden-lg-and-up"},[_c('v-btn',{staticClass:"mr-3 grey darken-2",attrs:{"dark":"","icon":"","to":"/"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-home")])],1),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"grey darken-2",attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.smallMenuItems),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.route}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),1)],1)],1),_c('v-spacer',{staticClass:"hidden-sm-and-down"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }