<template>
  <v-container fluid class="pa-0 ma-0">
    <!-- Ligne d'en-tête -->
    <v-row class="pa-0 ma-0 ribon">
      <v-col>
        <h1>Nos tarifs</h1>
      </v-col>
    </v-row>
    <v-row justify="center" class="pa-0 ma-0">
      <v-col lg="6" md="8" sm="10" xs="12" align="center">
        <p style="font-size: 16pt;">
          Ensemble nous établissons un devis, et nous nous engageons à le respecter !
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" class="pa-0 ma-0">
      <v-col lg="6">
        <v-simple-table min-height="300px">
          <template v-slot:default>
            <thead>
              <tr>
                <th width="450px" class="text-left emph hd">
                </th>
                <th width="135px" class="text-left emph hd">
                  <p>HT</p>
                </th>
                <th width="135px" class="text-left emph hd">
                  <p>TVA 10%</p>
                </th>
                <th width="135px" class="text-left emph hd">
                  <p>TVA 20%</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="emph">
                  <p>Main d'oeuvre</p>
                </td>
                <td class="emph bluet">
                  <p>47,00€</p>
                </td>
                <td class="emph bluet">
                  <p>51,70€</p>
                </td>
                <td class="emph bluet">
                  <p>56,40€</p>
                </td>
              </tr>
              <tr>
                <td class="emph">
                  <p>Main d'oeuvre hautement qualifiée</p>
                </td>
                <td class="emph bluet">
                  <p>65,00€</p>
                </td>
                <td class="emph bluet">
                  <p>71,50€</p>
                </td>
                <td class="emph bluet">
                  <p>78,00€</p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card class="my-6">
          <v-img
            height="300"
            :src="require('@/assets/img/hand-shake_1280.jpg')"
          >
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>
.ribon {
  background: rgba(112, 189, 205, 1);
}
.emph p {
  font-size: 16pt;
}
.bluet p {
  color: darkblue;
}
.hd {
  background: rgb(222, 220, 217);
}
.hd p {
  color: crimson;
}
</style>