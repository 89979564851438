<template>
  <v-container fluid class="pa-0 ma-0 main-row">
    <!-- HEADER -->
    <v-row class="pa-0 ma-0 ribon">
      <v-col>
        <h1>Mentions Légales</h1>
        <h2>
            <a href="https://www.cefed.fr">
                www.cefed.fr
            </a>
        </h2>
      </v-col>
    </v-row>
    <!-- Avant-propos
    <v-row justify="center" class="pa-0 ma-0">
      <v-col lg="8" md="8" sm="10" xs="12" align="center">
        https://www.societe.com/societe/cefed-492056502.html
        https://www.legalplace.fr/contrats/mentions-legales/

      </v-col>
    </v-row> -->
    <!-- LIGNE 1 -->
    <v-row class="pa-0 mx-0 mt-6 pb-6" justify="center">
      <v-col lg="3" md="5" sm="10" xs="12">
        <v-card class="mcard">
          <v-toolbar
            dense
            class="white--text elevation-0"
            color="grey darken-1"
          >
            <!-- <v-icon dark large class="mr-4">mdi-ev-station</v-icon> -->
            <v-toolbar-title>
              <h2 style="font-variant: small-caps">Avant-propos</h2>
            </v-toolbar-title>
          </v-toolbar>
          <p class="pa-3" style="font-size: 12pt; text-align: justify">
            Conformément aux dispositions des Articles 6-III et 19 de la Loi
            n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie
            numérique, dite L.C.E.N., il est porté à la connaissance des
            utilisateurs et visiteurs, ci-après l""Utilisateur", du site
            www.cefed.fr , ci-après le "Site", les présentes mentions légales.
            <br />
            La connexion et la navigation sur le Site par l’Utilisateur implique
            acceptation intégrale et sans réserve des présentes mentions
            légales.
            <br />
            Ces dernières sont accessibles sur le Site à la rubrique « Mentions
            légales ».
          </p>
        </v-card>
      </v-col>
      <v-col lg="5" md="7" sm="10" xs="12">
        <v-card class="mcard">
          <v-toolbar
            dense
            class="white--text elevation-0"
            color="grey darken-1"
          >
            <v-icon dark class="mr-4">mdi-arrow-right-thick</v-icon>
            <v-toolbar-title>
              <h3 style="font-variant: small-caps">Article 1 - L'éditeur</h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
              L’édition et la direction de la publication du Site est assurée
              par Florian FERNANDES, domiciliée 417 Boulevard du Cami Salié,
              dont le numéro de téléphone est 0652988719, et l'adresse e-mail
              fernandes.florian@live.fr. ci-après l'"Editeur".
            </p>
          </v-card-text>
          <v-toolbar
            dense
            class="white--text elevation-0"
            color="grey darken-1"
          >
            <v-icon dark class="mr-4">mdi-arrow-right-thick</v-icon>
            <v-toolbar-title>
              <h3 style="font-variant: small-caps">Article 2 - L'Hébergeur</h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
              L'hébergeur du Site est la société SAS OVH, dont le siège social
              est situé au 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1,
              avec le numéro de téléphone : 0820320363 + adresse mail de contact
            </p>
          </v-card-text>
          <v-toolbar
            dense
            class="white--text elevation-0"
            color="grey darken-1"
          >
            <v-icon dark class="mr-4">mdi-arrow-right-thick</v-icon>
            <v-toolbar-title>
              <h3 style="font-variant: small-caps">
                Article 3 - Accès au site
              </h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
              Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de
              force majeure, interruption programmée ou non et pouvant découlant
              d’une nécessité de maintenance.
              <br />
              En cas de modification, interruption ou suspension du Site,
              l'Editeur ne saurait être tenu responsable.
            </p>
          </v-card-text>
          <v-toolbar
            dense
            class="white--text elevation-0"
            color="grey darken-1"
          >
            <v-icon dark class="mr-4">mdi-arrow-right-thick</v-icon>
            <v-toolbar-title>
              <h3 style="font-variant: small-caps">
                Article 4 - Collecte des données
              </h3>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p class="parag">
              Le Site assure à l'Utilisateur une collecte et un traitement
              d'informations personnelles dans le respect de la vie privée
              conformément à la loi n°78-17 du 6 janvier 1978 relative à
              l'informatique, aux fichiers et aux libertés. En vertu de la loi
              Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur
              dispose d'un droit d'accès, de rectification, de suppression et
              d'opposition de ses données personnelles.
              <br /><br />
              L'Utilisateur exerce ce droit :
              <br />
              · via un formulaire de contact ;
              <br /><br />
              Toute utilisation, reproduction, diffusion, commercialisation,
              modification de toute ou partie du Site, sans autorisation de
              l’Editeur est prohibée et pourra entraînée des actions et
              poursuites judiciaires telles que notamment prévues par le Code de
              la propriété intellectuelle et le Code civil.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      topCarouselItems: [
        {
          name: "Cable Schneider Electric",
          img: require("@/assets/img/EV-Link-Schneider-Electric.png"),
        },
        {
          name: "Borne de charge Schneider Electric",
          img: require("@/assets/img/EV-Link-Schneider-Electric.png"),
        },
      ],
      carouselItems: [
        {
          name: "Cable Schneider Electric",
          img: require("@/assets/img/slideshow-bornes/ze-cable.jpg"),
        },
        {
          name: "Borne de charge Schneider Electric",
          img: require("@/assets/img/slideshow-bornes/ze-charge.jpg"),
        },
        {
          name: "Charge schneider",
          img: require("@/assets/img/slideshow-bornes/ze-pack-renault.jpg"),
        },
        {
          name: "Charge schneider",
          img: require("@/assets/img/slideshow-bornes/ze-prise.jpg"),
        },
        {
          name: "Charge schneider",
          img: require("@/assets/img/slideshow-bornes/ze-public.jpg"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.rad {
  border-radius: 5px;
  margin-bottom: 10px;
}
.main-row {
  background: rgba(242, 238, 229, 1);
}
.ribon {
  background: rgba(112, 189, 205, 1);
}
.mcard {
  /* height: 550px; */
}
.ccard {
  /* height: 350px; */
}
.cncard {
  height: 350px;
}
.parag {
  text-align: justify;
  font-size: 14pt;
}
</style>