<template>
  <v-container fluid class="pa-0 ma-0 main-row">
    <!-- Ligne d'en-tête -->
    <v-row class="pa-0 ma-0 ribon">
      <v-col>
        <h1>Nos tarifs d'installation d'une borne électrique</h1>
      </v-col>
    </v-row>
    <v-row justify="center" class="pa-0 ma-0">
      <v-col lg="6" md="8" sm="10" xs="12" align="center">
        <p style="font-size: 16pt;">
            Un prix 100% tout compris. Avec CEFED, les prix sont garantis sans mauvaise surprise. <br>
            Ensemble nous établissons un devis, et nous nous engageons à le respecter !
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" class="pa-0 mx-0 mt-6 pb-6">
        <v-col lg="5" md="7" sm="10" xs="12">
            <v-simple-table min-height="300px">
            <template v-slot:default>
                <thead>
                <tr>
                    <th width="135px" class="text-left emph hd">
                    <p>TVA 10%</p>
                    </th>
                    <th width="135px" class="text-left emph hd">
                    <p>TVA 20%</p>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td class="emph bluet">
                    <p>915€ TTC</p>
                    </td>
                    <td class="emph bluet">
                    <p>995€ TTC</p>
                    </td>
                </tr>
                </tbody>
            </template>
            </v-simple-table>
            <v-card class="my-6">
            <v-img
                height="400"
                alt="Dessin recharge electrique CEFED"
                :src="require('@/assets/img/electrical-1280.jpg')"
            >
            </v-img>
            </v-card>
        </v-col>
        <v-col lg="3" md="5" sm="10" xs="12">
          <GammeBorne />
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GammeBorne from "@/components/Bornes/Gamme.vue"

export default {
    components: {
      GammeBorne
    },
}
</script>

<style scoped>
.main-row {
  background: rgba(242, 238, 229, 1);
}
.ribon {
  background: rgba(112, 189, 205, 1);
}
td {
    vertical-align: middle;
    height: 45px;
}
.emph p {
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
    font-size: 16pt;
}
.bluet p {
  color: darkblue;
}
.hd {
  background: rgb(222, 220, 217);
}
.hd p {
  color: crimson;
}
</style>