<template>
  <div class="text-center">
    <v-snackbar
        :color="snackBar.category"
        v-model="snackBar.visible"
        :timeout="snackBar.timeout"
    >
      {{ snackBar.msg }}

      <template v-slot:action="{ attrs }">
        <v-btn
          icon large
          v-bind="attrs"
          @click="snackBar.visible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    computed: {
      snackBar: {
        get() {
            return this.$store.getters.getSnack;
        },
        set(nVal) {
            this.$store.commit('setSnack', nVal)
        }
      }
    }
}
</script>

<style>

</style>