<template>
  <v-container class="mainRow pa-0 ma-0" fluid>
      <v-row class="pa-0 ma-0 ribon">
          <v-col>
              <h1>L'entreprise</h1>
          </v-col>
      </v-row>

      <v-row fuild class="pa-0 py-6 ma-0" justify="center">
          <v-col cols="12" align="center">
            <h1 class="divider">
                Qui sommes nous ?
            </h1>
          </v-col>
          <v-col lg="2" md="1" class="hidden-sm-and-down"></v-col>
          <v-col lg="4" md="5" sm="6" xs="12" >
              <v-card class="rad">
                  <v-img
                    
                    :src="require('@/assets/img/Entreprise-Qualifications.png')"
                  >
                  </v-img>
              </v-card>
          </v-col>
          <v-col lg="4" md="5"  sm="6" xs="12" class="pa-2 ma-0">
                <h2 class="lttleHead">L'entreprise</h2>
                <p style="text-align: justify;">
                    L’entreprise CEFED (Chaud, Electricité, Froid, Entretien, Dépannage)
                    a été créée en 2006.
                </p>

                <h2 class="lttleHead">Nos domaines</h2>
                <p style="text-align: justify;">Nous sommes spécialisés dans la prestation de service comme le dépannage, 
                l’entretien et la maintenance dans divers corps de métiers des fluides 
                du bâtiment et de l’industrie.</p>
          </v-col>
          <v-col lg="2" md="1" class="hidden-sm-and-down"></v-col>
      </v-row>
      <v-row class="pa-0 ma-0" justify="center">
          <v-col lg="6" md="8" sm="12" xs="12">
              <h1 class="divider">Les véhicules électriques</h1>
          </v-col>
      </v-row>
      <!-- Ligne 2 : Les véhicules électriques -->
      <v-row class="pa-0 py-6 ma-0" justify="center">
          <v-col lg="2" md="1" class="hidden-sm-and-down"></v-col>
          <v-col lg="4" md="4" sm="6" xs="12">
                <p style="text-align: justify;">
                    Nous sommes également solidement impliqués dans le développement des véhicules électriques 
                    et de ce fait dans la pose des équipements de recharge de ceux-ci.
                </p>
                <p style="text-align: justify;">
                    Etant fortement à l’écoute de nos clients nous leur portons une attention particulière
                    afin de définir au mieux et dans un bon rapport qualité/prix leurs besoins.
                </p>
                <p style="text-align: justify;">
                    Portant un intérêt au respect drastique des règlementations et normes en vigueur, 
                    nous mettons un point d’honneur au tri et recyclage de nos déchets.
                </p>
                
          </v-col>
          <v-col lg="4" md="6" sm="6" xs="12">
              <v-card class="rad mb-6" style="width: 600px;">
                <v-img
                    style="height: 400px;"
                    :src="require('@/assets/img/parking-space_640.jpg')"
                >
                </v-img>
              </v-card>
          </v-col>
          <v-col lg="2" md="1" class="hidden-sm-and-down"></v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>
.rad {
    border-radius: 15px;
}
.ribon { 
    background: rgba(112, 189, 205, 1);
}
.mainRow {
   background: rgba(242, 238, 229, 1);
}
.lttleHead {
    font-size: 16pt;
    color: rgb(65, 65, 65);
    margin-bottom: 15px;
}
.divider{
    color:rgb(105, 105, 105);
    width:70%;
    margin:20px auto;
    overflow:hidden;
    text-align:center;   
    line-height:1.2em;
}

.divider:before, .divider:after{
    content:"";
    vertical-align:middle;
    display:inline-block;
    width:50%;
    border-bottom:2px dotted #ccc;
    margin:0 2% 0 -55%;
}
.divider:after{
    margin:0 -55% 0 2%;
}
</style>